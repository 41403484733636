
import React, { useState } from "react";
import Header from './Header';
import Footer from './Footer';
import Slider from "react-slick";
import $ from "jquery";

// Import all Images
import qrSanner from '../assets/images/home/qr-sanner.webp';
import whyus1 from '../assets/images/home/whyus1.svg';
import whyus2 from '../assets/images/home/whyus2.svg';
import whyus3 from '../assets/images/home/whyus3.svg';
import whyus4 from '../assets/images/home/whyus4.svg';
import featuremob1 from '../assets/images/home/feature-mob-1.webp';
import featuremob2 from '../assets/images/home/feature-mob-2.webp';
import featuremob3 from '../assets/images/home/feature-mob-3.webp';
import featuremob4 from '../assets/images/home/feature-mob-4.webp';
import featureDesk1 from '../assets/images/home/iphone-desk-1.webp';
import featureDesk2 from '../assets/images/home/iphone-desk-2.webp';
import featureDesk3 from '../assets/images/home/iphone-desk-3.webp';
import featureDesk4 from '../assets/images/home/iphone-desk-4.webp';
import ninad from '../assets/images/home/ninad.webp';
import surbhi from '../assets/images/home/surbhi.webp';
import sankalp from '../assets/images/home/sankalp.webp';
import kiran from '../assets/images/home/kiran.webp';
import appstore from '../assets/images/home/appstore.webp';
import playstore from '../assets/images/home/playstore.webp';
import instaRound from '../assets/images/home/insta-round.webp';

import qrCode2 from '../assets/images/home/qr-code-2.webp';
import done from '../assets/images/home/Done.webp';
import khichiqGrouped from '../assets/images/home/khichiq-grouped.svg';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = () => {
	const [nav1, setNav1] = useState();
	const [nav2, setNav2] = useState();
	const [activeIndex, setActiveIndex] = useState(0);
	const [formVisible, setFormVisible] = useState(false);
	const [linkCopied, setLinkCopied] = useState(false);

	const adjustSliderHeight = () => {
		$(".TextSlider .slick-slide").each(function () {
			if ($(this).hasClass("slick-current")) {
				const currentHeight = $(this).find(".hidedesc").outerHeight();
				$(".TextSlider .slick-list").css("height", currentHeight + "px");
			}
		});
	};

	setTimeout(adjustSliderHeight, 500);

	$(".TextSlider").on("afterChange", adjustSliderHeight);
	$(window).on("resize", adjustSliderHeight);

	const textSliderSettings = {
		slidesToShow: 4,
		infinite: false,
		vertical: true,
		autoplay: false,
		focusOnSelect: true,
		draggable: false,
		arrows: false,
	};

	const featureSliderSettings = {
		vertical: true,
		slidesToShow: 1,
		autoplay: false,
		arrows: false,
		focusOnSelect: true,
	};
	const reviewSliderSettings = {
		dots: true,
		arrows: false,
		infinite: true,
		speed: 800,
		slidesToShow: 3,
		autoplay: false,
		adaptiveHeight: true,
		centerMode: false,
		variableWidth: false,
		responsive: [
			{
				breakpoint: 1025,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					centerMode: false,
					centerPadding: "0px",
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					centerMode: false,
					centerPadding: "0px",
				},
			},
			{
				breakpoint: 700,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: false,
					centerPadding: "0px",
				},
			},
		],
	};

	const handleClick = (index) => {
		console.log(index);
		setActiveIndex(index === activeIndex ? -1 : index);
	};

	const qaList = [
		{
			question: 'What is Khichiq?',
			answer: 'We are looking for early adopters who can try out and use our new app for themselves.'
		},
		{
			question: 'Why Choose Khichiq?',
			answer: 'Khichiq gives you everything you need to organize your photography business – managing orders & teammates, collaborating with free freelancers, and tracking payments. We aim to be the go-to app for running your photography business from anywhere, anytime.'
		},
		{
			question: 'What Benefits Will I Receive?',
			answer: 'You will receive free access to our new photography business management app, allowing you to manage your photography business fully.'
		},
		{
			question: 'Does It Cost Anything?',
			answer: "No, it's currently free. Additionally, we will provide benefits to our early adopters when we introduce subscription fees in the future. Our subscription plan will be priced at less than ₹2500 annually, ensuring affordability for the majority of our users."
		},
		{
			question: 'How Can I Sign Up and Log In to the App?',
			answer: "You can sign up and log in with your mobile number, completing OTP validation. Upon your first login, you'll be prompted to provide basic business details to create a business profile."
		},
		{
			question: 'How Can I Invite Team Members or Freelancers to My Business?',
			answer: "Easily invite team members or freelancers by entering their mobile number and name. Upon successful invitation, they'll receive an invitation link via SMS. If they're already on our platform, we'll simply notify them that you've added them to your team."
		}
	];
	const toggleFormVisibility = () => {
		setFormVisible(!formVisible);
	};
	const handleLinkCopy = (e) => {
		e.preventDefault();
		const copyText = e.target.getAttribute('data-url');
		navigator.clipboard.writeText(copyText).then(() => {
		  setLinkCopied(true);
		  setTimeout(() => {
			setLinkCopied(false);
		  }, 5000);
		});
	  };

	return (
		<>
			<Header />
			{formVisible && (
        <div className="overlay-custom poptheform" id="overlay">
          <div className="popup-custom">
            <button className="close-custom" onClick={toggleFormVisibility} data-event-category="Section" data-event-action="Click" data-event-name="Close">
              <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.5155 10.4678C20.5155 5.22891 16.1518 0.978516 10.7733 0.978516C5.39473 0.978516 1.03101 5.22891 1.03101 10.4678C1.03101 15.7066 5.39473 19.957 10.7733 19.957C16.1518 19.957 20.5155 15.7066 20.5155 10.4678Z" stroke="#C4C4C4" strokeOpacity="0.8" strokeWidth="1.33333" strokeMiterlimit="10" />
                <path d="M7.52637 13.6313L14.0212 7.30518M14.0212 13.6313L7.52637 7.30518L14.0212 13.6313Z" stroke="#C4C4C4" strokeOpacity="0.8" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
            <div className="formdataWrapper text-center">
              <div className="logo">
                <img className="img-responsive" src={khichiqGrouped} alt="Khichiq" />
              </div>
              <div className="content">
                Be among the first ones to experience it! <br /> Scan QR to get the App
              </div>
              <div className="qr-scanner">
                <img className="img-responsive" src={qrCode2} alt="QR" />
              </div>
              <div className="theme-btn-wrapper clicktoshareWraper">
                <button onClick={handleLinkCopy} className="theme-btn clicktoshare" data-url="https://www.khichiq.com/">SHARE WITH FRIENDS</button>
              </div>
              {linkCopied && (
                <div className="closingtxtWrapper" style={{ display: 'flex' }}>
                  <div>
                    <img className="img-responsive" src={done} alt="Tick" />
                  </div>
                  <div className="closingtxt">
                    Link copied! Paste it on any platform to share!
                  </div>
                </div>
              )}
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      )}
			<section className="section-first pos-rel banner_ht bg-cover home-banner outfit-font" id="link1">
				<div className="qrWrapper text-center hidden-md">
					<div className="qrdata">
						<p>Get the App Now!</p>
						<img className="img-responsive" src={qrSanner} alt="QR" />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="containerleft">
							<div className="home-banner-cont">
								<div className="banner-data">
									<div className="prefix">Your All-In-One</div>
									<div className="title">
										Mobile 1st Photography <br /> Business App
									</div>
									<div className="content">
										Manage your Orders, Events, <br className="visible-xs" />Payments & <br className="hidden-xs" /> Team effortlessly via App!
									</div>
									<div className="theme-btn-wrapper">
										<a href="javascript:;" onClick={toggleFormVisibility} className="open-form theme-btn hovtype1 text-uppercase">GET FREE ACCESS</a>
									</div>
									<div className="closingtxt">A platform designed for your GROWTH!</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="padding overview outfit-font" id="overview">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h2 className="secTitle text-center">Photographer <span className="sc">LOVE</span> Us because...</h2>
							<div className="whyusWrapper">
								<div className="item">
									<div className="icon">
										<img src={whyus1} alt="whyus1" />
									</div>
									<div className="content">
										Unique Dashboard <br className="visible-xs" /> for business overview
									</div>
								</div>
								<div className="item">
									<div className="icon">
										<img src={whyus2} alt="whyus2" />
									</div>
									<div className="content">
										Build & Manage <br className="visible-xs" /> your photography team
									</div>
								</div>
								<div className="item">
									<div className="icon">
										<img src={whyus3} alt="whyus3" />
									</div>
									<div className="content">
										Track everything <br className="visible-xs" /> from lead to events
									</div>
								</div>
								<div className="item">
									<div className="icon">
										<img src={whyus4} alt="whyus4" />
									</div>
									<div className="content">
										Unique Dashboard <br className="visible-xs" /> for business overview
									</div>
								</div>
							</div>
							<div className="text-center prevutxt">This is just a preview. We are building much more for you!</div>
						</div>
					</div>
				</div>
			</section>

			<section className="padding features outfit-font" id="features">
				<div className="container">
					<div className="row">
						<h2 className="secTitle text-center">Key Features of <span className="sc">KHICHIQ</span></h2>
						<div className="col-6 col-sm-12">
							<div className="TextSliderWrapper">
								<Slider className="TextSlider" asNavFor={nav2} ref={(slider1) => setNav1(slider1)} {...textSliderSettings}>
									<div className="slide">
										<div className="title">Lead Your Team <br className="visible-xs" /> Like a PRO!</div>
										<div className="hidedesc">
											<div className="content">
												Powerful team and order management tool designed for photographers
											</div>
											<div className="theme-btn-wrapper hidden-sm">
												<a href="javascript:;" onClick={toggleFormVisibility} className="open-form theme-btn hovtype2">TRY NOW!</a>
											</div>
											<div className="visible-sm featuremob">
												<img src={featuremob1} alt="iphone" className="img-responsive" />
											</div>
										</div>
									</div>
									<div className="slide">
										<div className="title">Seamless <br className="visible-xs" /> Financial Management</div>
										<div className="hidedesc">
											<div className="content">
												Easily track income, expenses, and taxes like a pro. Let’s take control of your business financials
											</div>
											<div className="theme-btn-wrapper hidden-sm">
												<a href="javascript:;" onClick={toggleFormVisibility} className="open-form theme-btn hovtype2">TRY NOW!</a>
											</div>
											<div className="visible-sm featuremob">
												<img src={featuremob2} alt="iphone" className="img-responsive" />
											</div>
										</div>
									</div>
									<div className="slide">
										<div className="title">Collaborate with <br className="visible-xs" /> Freelancers</div>
										<div className="hidedesc">
											<div className="content">
												Unite! Find, Connect, and Thrive. Work with freelancers and collaborate to grow your business
											</div>
											<div className="theme-btn-wrapper hidden-sm">
												<a href="javascript:;" onClick={toggleFormVisibility} className="open-form theme-btn hovtype2">TRY NOW!</a>
											</div>
											<div className="visible-sm featuremob">
												<img src={featuremob3} alt="iphone" className="img-responsive" />
											</div>
										</div>
									</div>
									<div className="slide">
										<div className="title">Business Analyatics <br className="visible-xs" /> on your Fingertips</div>
										<div className="hidedesc">
											<div className="content">
												Say goodbye to boring spreadsheets and hello to valuable insights into your photography business
											</div>
											<div className="theme-btn-wrapper hidden-sm">
												<a href="javascript:;" onClick={toggleFormVisibility} className="open-form theme-btn hovtype2">TRY NOW!</a>
											</div>
											<div className="visible-sm featuremob">
												<img src={featuremob4} alt="iphone" className="img-responsive" />
											</div>
										</div>
									</div>
								</Slider>
							</div>
						</div>
						<div className="col-6 secondcol hidden-sm outfit-font">
							<div className="featureSliderWrapper">
								<Slider className="featureSlider" asNavFor={nav1}
									ref={(slider2) => setNav2(slider2)}  {...featureSliderSettings}>
									<div className="slide">
										<img src={featureDesk1} alt="" className="img-responsive" />
									</div>
									<div className="slide">
										<img src={featureDesk2} alt="" className="img-responsive" />
									</div>
									<div className="slide">
										<img src={featureDesk3} alt="" className="img-responsive" />
									</div>
									<div className="slide">
										<img src={featureDesk4} alt="" className="img-responsive" />
									</div>
								</Slider>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="padding purpose outfit-font" id="purpose">
				<div className="container">
					<div className="row">
						<h2 className="text-center secTitle">Who Are <span className="sc">WE</span>?</h2>
						<p className="para text-center text-white para1">
							Welcome to Khichiq, where we go beyond just creating an app we re shaping a solution specifically for photographers, by
							photographers who comprehend the distinctive hurdles of running a photography business. Explore our innovative platform,
							streamline your work, amplify your results, revolutionising how you navigate and enhance your business management.
						</p>
						<h2 className="text-center secTitle title2">Our Purpose</h2>
						<p className="para text-center text-white para2">&quot;Focus on your art, we handle the rest. Manage every click, from client <br /> bookings to project budgets, with Khichiq&quot;</p>
					</div>
				</div>
			</section>

			<section className="padding builtwith outfit-font" id="builtwith">
				<div className="container">
					<div className="row">
						<h2 className="text-center secTitle">Built with <br className="visible-xs" /><span className="sc">100+</span> Photographers</h2>
						<div className="builtwithWrapper">
							<div className="item">
								<div className="title">
									Built for You!
								</div>
								<div className="content">
									<p>Khichiq is built by photographers, for photographers just like you! We ve worked and gathered feedback of more than 100 photographers to give you the best possible product. A lot to experience!</p>
								</div>
							</div>
							<div className="item">
								<div className="title">
									Experience the Future
								</div>
								<div className="content">
									<p>We re inviting you to be part of something revolutionary. Khichiq is not just an app; it s a community. As an early adopter, you ll get a firsthand look at our Khichiq app, the future of photography business management.</p>
								</div>
							</div>
							<div className="item">
								<div className="title">
									It&apos;s Free, Absolutely Free!
								</div>
								<div className="content">
									<p>Becoming an early adopter won t cost you a dime. We believe in empowering photographers without burdening your budget. Khichiq is, and will always be, free of cost. No hidden charges, no subscription fees just powerful tools at your fingertips.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="padding reviews outfit-font" id="reviews">
				<div className="container">
					<div className="row">
						<h2 className="secTitle text-center">User <span className="sc">REVIEWS</span> for us</h2>
					</div>
				</div>
				<div className="containerleft outfit-font">
					<div className="row">
						<div className="reviewSliderWrapper">
							<Slider className="reviewSlider" {...reviewSliderSettings}>
								<div className="slide">
									<div className="box text-white">
										<div className="clientinfo">
											<div className="clientdata">
												<div className="flex align-center justify-center">
													<img className="img-responsive clientimg" src={ninad} alt="client1" />
												</div>
												<div className="clientname">
													<h3>Ninad</h3>
													<p>Alpha Cinema</p>
												</div>
											</div>
											<div>
												<img src={appstore} alt="logo" />
											</div>
										</div>
										<div className="content">
											Khichiq is built by photographers, for photographers just like you! We’ve worked and gathered feedback of more than 100 photographers to give you the...
										</div>
									</div>
								</div>
								<div className="slide">
									<div className="box text-white">
										<div className="clientinfo">
											<div className="clientdata">
												<div className="flex align-center justify-center">
													<img className="img-responsive clientimg" src={surbhi} alt="client1" />
												</div>
												<div className="clientname">
													<h3>Surbhi</h3>
													<p>Surbhi’s Portraits</p>
												</div>
											</div>
											<div>
												<img src={playstore} alt="logo" />
											</div>
										</div>
										<div className="content">
											Khichiq is built by photographers, for photographers just like you! We’ve worked and gathered feedback of more than...
										</div>
									</div>
								</div>
								<div className="slide">
									<div className="box text-white">
										<div className="clientinfo">
											<div className="clientdata">
												<div className="flex align-center justify-center">
													<img className="img-responsive clientimg" src={sankalp} alt="client1" />
												</div>
												<div className="clientname">
													<h3>Sankalp</h3>
													<p>Superb Weddings</p>
												</div>
											</div>
											<div>
												<img src={instaRound} alt="logo" />
											</div>
										</div>
										<div className="content">
											Khichiq is built by photographers, for photographers just like you!
										</div>
									</div>
								</div>
								<div className="slide">
									<div className="box text-white">
										<div className="clientinfo">
											<div className="clientdata">
												<div className="flex align-center justify-center">
													<img className="img-responsive clientimg" src={kiran} alt="client1" />
												</div>
												<div className="clientname">
													<h3>Kiran</h3>
													<p>Kiran Weddings</p>
												</div>
											</div>
											<div>
												<img src={instaRound} alt="logo" />
											</div>
										</div>
										<div className="content">
											Khichiq is built by photographers, for photographers just like you!
										</div>
									</div>
								</div>
							</Slider>
						</div>
					</div>
				</div>
				<div className="container text-center">
					<div className="reviewdots"></div>
				</div>
			</section>

			<section className="padding khichiquer outfit-font" id="khichiquer">
				<div className="container">
					<div className="row">
						<h2 className="secTitle text-center">Be a <span className="sc">KHICHIQUER!</span></h2>
						<p className="para text-white text-center para1">
							Don&apos;t miss the opportunity to revolutionise how you manage your photography business. Join us on this exciting journey as we redefine the standard for photography business management.
						</p>
						<div className="text-center theme-btn-wrapper">
							<a href="javascript:;" onClick={toggleFormVisibility}  className="theme-btn hovtype2 open-form text-center text-white text-bold">JOIN COMMUNITY</a>
						</div>
					</div>
				</div>
			</section>

			<section className="padding answers outfit-font" id="answers">
				<div className="container">
					<div className="row">
						<h2 className="secTitle text-center"><span className="sc">ANSWERS</span> to your Basic Questions...</h2>
						<div className="qaAccordWrapper">
							<div className="qaAccord">
								<ul className="accordion-list">
									{qaList.map((qa, index) => (
										<li key={index} className={index === activeIndex ? 'active' : ''}>
											<a
												className="accordion-header"
												onClick={() => handleClick(index)}
												onKeyPress={() => handleClick(index)}
											>
												<h3 className="text-center text-white">{qa.question}</h3>
											</a>
											{index === activeIndex && (
												<div className="answer text-white text-center">
													<p>{qa.answer}</p>
												</div>
											)}
										</li>
									))}
								</ul>
							</div>
						</div>
						<div className="text-center theme-btn-wrapper outfit-font">
							<a href="/faq" target="_blank" className="theme-btn text-center text-white text-bold hovtype2">VISIT FAQs</a>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default Home;
