
import React, { useState } from "react";
import Header from './Header';
import Footer from './Footer';

// Import all Images
import pageInfo from '../assets/images/home/page-info.svg';
import qrCode2 from '../assets/images/home/qr-code-2.webp';
import done from '../assets/images/home/Done.webp';
import khichiqGrouped from '../assets/images/home/khichiq-grouped.svg';

const TermsConditions = () => {
  const [formVisible, setFormVisible] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  const toggleFormVisibility = () => {
    setFormVisible(!formVisible);
  };
  const handleLinkCopy = (e) => {
    e.preventDefault();
    const copyText = e.target.getAttribute('data-url');
    navigator.clipboard.writeText(copyText).then(() => {
      setLinkCopied(true);
      setTimeout(() => {
        setLinkCopied(false);
      }, 5000);
    });
  };

  return (
    <>
      <Header />
      {formVisible && (
        <div className="overlay-custom poptheform" id="overlay">
          <div className="popup-custom">
            <button className="close-custom" onClick={toggleFormVisibility} data-event-category="Section" data-event-action="Click" data-event-name="Close">
              <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.5155 10.4678C20.5155 5.22891 16.1518 0.978516 10.7733 0.978516C5.39473 0.978516 1.03101 5.22891 1.03101 10.4678C1.03101 15.7066 5.39473 19.957 10.7733 19.957C16.1518 19.957 20.5155 15.7066 20.5155 10.4678Z" stroke="#C4C4C4" strokeOpacity="0.8" strokeWidth="1.33333" strokeMiterlimit="10" />
                <path d="M7.52637 13.6313L14.0212 7.30518M14.0212 13.6313L7.52637 7.30518L14.0212 13.6313Z" stroke="#C4C4C4" strokeOpacity="0.8" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
            <div className="formdataWrapper text-center">
              <div className="logo">
                <img className="img-responsive" src={khichiqGrouped} alt="Khichiq" />
              </div>
              <div className="content">
                Be among the first ones to experience it! <br /> Scan QR to get the App
              </div>
              <div className="qr-scanner">
                <img className="img-responsive" src={qrCode2} alt="QR" />
              </div>
              <div className="theme-btn-wrapper clicktoshareWraper">
                <button onClick={handleLinkCopy} className="theme-btn clicktoshare" data-url="https://www.khichiq.com/">SHARE WITH FRIENDS</button>
              </div>
              {linkCopied && (
                <div className="closingtxtWrapper" style={{ display: 'flex' }}>
                  <div>
                    <img className="img-responsive" src={done} alt="Tick" />
                  </div>
                  <div className="closingtxt">
                    Link copied! Paste it on any platform to share!
                  </div>
                </div>
              )}
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      )}

      <section className="page-title outfit-font">
        <div className="container">
          <div className="col-6 col-xs-12 text-xs-center text-right pull-right">
            <img src={pageInfo} alt="page info" />
          </div>
          <div className="col-6 col-xs-12 text-xs-center pull-left">
            <h1>Terms & Conditions</h1>
          </div>
        </div>
      </section>
      <section className="service-wrap faqpage outfit-font">
        <div className="container">
          <div className="col-12">
            <h2>General Terms</h2>
            <p>Welcome! These terms and conditions (&quot;Terms&quot;) outline the rules and regulations for using our photography business management application (&quot;Application&quot;). By accessing or using the Application, you agree to be bound by these Terms.</p>
            <h3>Acceptance of Terms  </h3>
            <p>You must have the photography business and have the legal authority to enter into agreements with us to use the Application.</p>

            <h3>Services Offered </h3>
            <p>The Application is designed to help photography businesses manage their workflow, clients, finances, and other aspects of their operations.</p>
            <p>We reserve the right to modify or discontinue the Application (or any part of it) at any time without notice. By using the Application, you acknowledge that you have read, understood, and agree to be bound by these Terms. </p>
            <p>User Accounts You may need to create an account to use certain features of the Application. You are responsible for maintaining the confidentiality of your account information and password. You agree to be fully responsible for all activities that occur under your account.</p>

            <h3>User Content </h3>
            <p>You are responsible for all content you upload, store, or transmit through the Application (&quot;User Content&quot;). You grant us a non-exclusive licence to use, reproduce, modify, and publish User Content in connection with the Application.</p>

            <h3>Intellectual Property </h3>
            <p>The Application and its content, features, and functionality are protected by copyrights, trademarks, and other intellectual property laws. You may not modify, reverse engineer, decompile, disassemble, or attempt to extract the source code of the Application.</p>

            <h3>Disclaimer</h3>
            <p>The Application is provided &quot;as is&quot; and without warranties of any kind, express or implied.</p>

            <h3>Governing Law</h3>
            <p>These Terms shall be governed and construed in accordance with the laws of India.</p>

            <h3>Entire Agreement</h3>
            <p>These Terms constitute the entire agreement between you and us regarding the use of the Application.</p>

            <h3>Updates to Terms</h3>
            <p>We may update these Terms at any time. You are responsible for checking these Terms periodically for changes.</p>

            <h3>Contact Us</h3>
            <p>If you have any questions about these Terms, please contact us at <a className="text-lightblue" href="mailto:hello@khichiq.com">hello@khichiq.com</a>.</p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default TermsConditions;
