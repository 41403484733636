import React, { useState } from "react";
import logo from '../assets/images/home/khiciq-logo.svg';
import logoMob from '../assets/images/home/khiciq-logo-mob.svg';
import iconInsta from '../assets/images/home/instagram.svg';
import iconFB from '../assets/images/home/facebook.svg';
import iconTW from '../assets/images/home/twitter.svg';
import qrCode2 from '../assets/images/home/qr-code-2.webp';
import done from '../assets/images/home/Done.webp';
import khichiqGrouped from '../assets/images/home/khichiq-grouped.svg';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const [formVisible, setFormVisible] = useState(false);
    const [linkInfoVisible, setLinkInfoVisible] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  

  const toggleFormVisibility = () => {
    setFormVisible(!formVisible);
  };

  const toggleLinkInfoVisibility = () => {
    setLinkInfoVisible(!linkInfoVisible);
  };

  const handleLinkCopy = (e) => {
    e.preventDefault();
    const copyText = e.target.getAttribute('data-url');
    navigator.clipboard.writeText(copyText).then(() => {
      setLinkCopied(true);
      setTimeout(() => {
        setLinkCopied(false);
      }, 5000);
    });
  };

    return (
        <>
            <footer className="bg-darkblue padding">
                <div className="container">
                    <div className="row">
                        <div className="footergrid">
                            <div className="firstcol">
                                <div className="logo">
                                    <div className="flex align-center">
                                        <div className="flex align-center justify-center gap-x-10">
                                            <div>
                                                <img className="hidden-sm" src={logo} alt="Logo" />
                                                <img className="visible-sm" src={logoMob} alt="Logo" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="address">
                                    <p>
                                        Sri Emerald Business Park <br /> Outer ring road, Marathahalli <br /> Bangalore 560037, Karnataka, India
                                    </p>
                                </div>
                                <div className="contact">
                                    <div className="mail">
                                        <a href="mailto:hello@khichiq.com">hello@khichiq.com</a>
                                    </div>
                                    <div className="whatsapp">Whatsapp - <a href="https://wa.me/6362306263">63 62 30 62 63</a></div>
                                </div>
                            </div>
                            <div className="secondcol">
                                <div className="nav1">
                                    <div>
                                        <a href="javascript:;" onClick={toggleLinkInfoVisibility}>About us</a>
                                    </div>
                                    <div>
                                        <a href="javascript:;" onClick={toggleLinkInfoVisibility}>Getting Started</a>
                                    </div>
                                    <div>
                                        <a href="javascript:;" onClick={toggleLinkInfoVisibility}>Share Feedback</a>
                                    </div>
                                    <div>
                                        <a href="javascript:;" onClick={toggleLinkInfoVisibility}>Contact us</a>
                                    </div>
                                </div>
                                <div className="nav2">
                                    <div>
                                        <a href="javascript:;" onClick={toggleLinkInfoVisibility}>Blogs</a>
                                    </div>
                                    <div>
                                        <a href="/privacy-policy">Privacy Policy</a>
                                    </div>
                                    <div>
                                        <a href="/terms-conditions">Terms & Conditions</a>
                                    </div>
                                    <div>
                                        <a href="/faq">FAQ</a>
                                    </div>
                                </div>
                                <div className="nav3">
                                    <div className="hidden-xs">
                                        <p className="text-white">Follow us on</p>
                                    </div>
                                    <a href="https://www.instagram.com/khichiq_app" className="sm">
                                        <img src={iconInsta} alt="Instagram" />
                                        <p className="hidden-xs">Instagram</p>
                                    </a>
                                    {/* <a href="javascript:;" className="sm">
                                        <img src={iconFB} alt="Facebook" />
                                        <p className="hidden-xs">Facebook</p>
                                    </a> */}
                                    <a href="https://x.com/khichiq" className="sm">
                                        <img src={iconTW} alt="Twitter" />
                                        <p className="hidden-xs">X</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="copytxt text-center">
                            Copyright <span className="copyrightYear">{currentYear}</span> @SNAPMAGIC TECHNOLOGIES LLP
                        </div>
                    </div>
                </div>
            </footer>
            <div className="mob-footer visible-xs">
                <div className="row">
                    <div className="col-6">
                        <a href="https://wa.me/6362306263" className="mob-call-btn"><span className="">63 62 30 62 63</span></a>
                    </div>
                    <div className="col-6">
                        <a href="javascript:;" onClick={toggleFormVisibility} className="open-form mob-enq-btn">Get FREE Access</a>
                    </div>
                </div>
            </div>
            {formVisible && (
        <div className="overlay-custom poptheform" id="overlay">
          <div className="popup-custom">
            <button className="close-custom" onClick={toggleFormVisibility} data-event-category="Section" data-event-action="Click" data-event-name="Close">
              <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.5155 10.4678C20.5155 5.22891 16.1518 0.978516 10.7733 0.978516C5.39473 0.978516 1.03101 5.22891 1.03101 10.4678C1.03101 15.7066 5.39473 19.957 10.7733 19.957C16.1518 19.957 20.5155 15.7066 20.5155 10.4678Z" stroke="#C4C4C4" strokeOpacity="0.8" strokeWidth="1.33333" strokeMiterlimit="10" />
                <path d="M7.52637 13.6313L14.0212 7.30518M14.0212 13.6313L7.52637 7.30518L14.0212 13.6313Z" stroke="#C4C4C4" strokeOpacity="0.8" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
            <div className="formdataWrapper text-center">
              <div className="logo">
                <img className="img-responsive" src={khichiqGrouped} alt="Khichiq" />
              </div>
              <div className="content">
                Be among the first ones to experience it! <br /> Scan QR to get the App
              </div>
              <div className="qr-scanner">
                <img className="img-responsive" src={qrCode2} alt="QR" />
              </div>
              <div className="theme-btn-wrapper clicktoshareWraper">
                <button onClick={handleLinkCopy} className="theme-btn clicktoshare" data-url="https://www.khichiq.com/">SHARE WITH FRIENDS</button>
              </div>
              {linkCopied && (
                <div className="closingtxtWrapper" style={{ display: 'flex' }}>
                  <div>
                    <img className="img-responsive" src={done} alt="Tick" />
                  </div>
                  <div className="closingtxt">
                    Link copied! Paste it on any platform to share!
                  </div>
                </div>
              )}
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      )}
      {linkInfoVisible && (
        <div className="overlay-custom poptheform" id="overlay">
          <div className="popup-custom">
            <button className="close-custom" onClick={toggleLinkInfoVisibility} data-event-category="Section" data-event-action="Click" data-event-name="Close">
              <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.5155 10.4678C20.5155 5.22891 16.1518 0.978516 10.7733 0.978516C5.39473 0.978516 1.03101 5.22891 1.03101 10.4678C1.03101 15.7066 5.39473 19.957 10.7733 19.957C16.1518 19.957 20.5155 15.7066 20.5155 10.4678Z" stroke="#C4C4C4" strokeOpacity="0.8" strokeWidth="1.33333" strokeMiterlimit="10" />
                <path d="M7.52637 13.6313L14.0212 7.30518M14.0212 13.6313L7.52637 7.30518L14.0212 13.6313Z" stroke="#C4C4C4" strokeOpacity="0.8" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
            <div className="formdataWrapper text-center">
              <p>We are in the process of building Khichiq from the ground up. Some things are already in place, while others are still a work in progress. Please stay tuned for our journey ahead!</p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      )}
        </>
    );
}
export default Footer;
