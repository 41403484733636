
import React, { useState } from "react";
import Header from './Header';
import Footer from './Footer';

// Import all Images
import pageInfo from '../assets/images/home/page-info.svg';
import qrCode2 from '../assets/images/home/qr-code-2.webp';
import done from '../assets/images/home/Done.webp';
import khichiqGrouped from '../assets/images/home/khichiq-grouped.svg';

const PrivacyPolicy = () => {
  const [formVisible, setFormVisible] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  const toggleFormVisibility = () => {
    setFormVisible(!formVisible);
  };
  const handleLinkCopy = (e) => {
    e.preventDefault();
    const copyText = e.target.getAttribute('data-url');
    navigator.clipboard.writeText(copyText).then(() => {
      setLinkCopied(true);
      setTimeout(() => {
        setLinkCopied(false);
      }, 5000);
    });
  };

  return (
    <>
      <Header />
      {formVisible && (
        <div className="overlay-custom poptheform" id="overlay">
          <div className="popup-custom">
            <button className="close-custom" onClick={toggleFormVisibility} data-event-category="Section" data-event-action="Click" data-event-name="Close">
              <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.5155 10.4678C20.5155 5.22891 16.1518 0.978516 10.7733 0.978516C5.39473 0.978516 1.03101 5.22891 1.03101 10.4678C1.03101 15.7066 5.39473 19.957 10.7733 19.957C16.1518 19.957 20.5155 15.7066 20.5155 10.4678Z" stroke="#C4C4C4" strokeOpacity="0.8" strokeWidth="1.33333" strokeMiterlimit="10" />
                <path d="M7.52637 13.6313L14.0212 7.30518M14.0212 13.6313L7.52637 7.30518L14.0212 13.6313Z" stroke="#C4C4C4" strokeOpacity="0.8" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
            <div className="formdataWrapper text-center">
              <div className="logo">
                <img className="img-responsive" src={khichiqGrouped} alt="Khichiq" />
              </div>
              <div className="content">
                Be among the first ones to experience it! <br /> Scan QR to get the App
              </div>
              <div className="qr-scanner">
                <img className="img-responsive" src={qrCode2} alt="QR" />
              </div>
              <div className="theme-btn-wrapper clicktoshareWraper">
                <button onClick={handleLinkCopy} className="theme-btn clicktoshare" data-url="https://www.khichiq.com/">SHARE WITH FRIENDS</button>
              </div>
              {linkCopied && (
                <div className="closingtxtWrapper" style={{ display: 'flex' }}>
                  <div>
                    <img className="img-responsive" src={done} alt="Tick" />
                  </div>
                  <div className="closingtxt">
                    Link copied! Paste it on any platform to share!
                  </div>
                </div>
              )}
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      )}

      <section className="page-title outfit-font">
        <div className="container">
          <div className="col-6 col-xs-12 text-xs-center text-right pull-right">
            <img src={pageInfo} alt="page info" />
          </div>
          <div className="col-6 col-xs-12 text-xs-center pull-left">
            <h1>Privacy Policy</h1>
          </div>
        </div>
      </section>
      <section className="service-wrap faqpage outfit-font">
        <div className="container">
          <div className="col-12">

            <p className="text-white">Welcome! This Privacy Policy explains how we collect, use, and disclose information from and about users of our photography business management application (&quot;KHICHIQ&quot;).</p>

            <h3>Information We Collect</h3>
            <p>We collect several types of information to provide and improve our Application:</p>

            <h3>Account Information </h3>
            <p>When you create an account, we collect basic information such as your name, email address, and password.</p>

            <h3>Usage Data</h3>
            <p>We collect information about how you interact with the Application, such as the features you use and the pages you visit.</p>

            <h3>Client and Project Data</h3>
            <p>You may store information about your clients and projects within the Application, such as names, contact details, and project details.</p>

            <h3>How We Use Your Information</h3>
            <p>We use the information we collect for the following purposes:</p>
            <ul>
              <li>To provide and maintain the Application.</li>
              <li>To improve and personalise the Application for you.</li>
              <li>To send you important information about the Application, such as updates and security notices.</li>
              <li>To comply with legal and regulatory requirements.</li>
            </ul>

            <h3>Sharing Your Information</h3>
            <p>We will not share your personal information with third parties except in the following limited circumstances:</p>
            <ul>
              <li>Service Providers: We may share your information with third-party service providers who help us operate the Application, such as data storage providers and analytics providers.</li>
              <li>Legal Requirements: We may disclose your information if required by law or to protect the rights and safety of ourselves or others.</li>
              <li>Business Transfers: In the event of a business transfer, such as a merger or acquisition, we may transfer your information to the new owner.</li>
            </ul>

            <h3>Data Security</h3>
            <p>We take reasonable steps to protect your information from unauthorised access, disclosure, alteration, or destruction. However, no internet transmission or electronic storage method is completely secure.</p>

            <h3>Your Choices</h3>
            <p>You have choices regarding your information:</p>
            <ul>
              <li>Access and Update: You can access and update your account information at any time.</li>
              <li>Data Deletion: You can request to delete your account and information at any time.</li>
            </ul>

            <h3>International Transfers</h3>
            <p>Your information may be transferred to and processed in countries other than your own. These countries may have different data protection laws than your own.</p>

            <h3>Changes to this Privacy Policy</h3>
            <p>We may update this Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

            <h3>Contact Us</h3>
            <p>If you have any questions about this Privacy Policy, please contact us at <a className="text-lightblue" href="mailto:hello@khichiq.com">hello@khichiq.com</a>.</p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
