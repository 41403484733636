import React, { useEffect, useState } from "react";
import $ from "jquery";
import logo from '../assets/images/home/khiciq-logo.svg';
import logoMob from '../assets/images/home/khiciq-logo.svg';
import qrCode2 from '../assets/images/home/qr-code-2.webp';
import done from '../assets/images/home/Done.webp';
import khichiqGrouped from '../assets/images/home/khichiq-grouped.svg';

const Header = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [gap, setGap] = useState(window.innerWidth <= 700 ? 55 : 100);
  const [formVisible, setFormVisible] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  useEffect(() => {
    const updateDimensions = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
      setGap(window.innerWidth <= 700 ? 55 : 100);
    };

    window.addEventListener("resize", updateDimensions);
    $(".banner_ht").css("height", windowHeight);

    const get_custom_margin = windowWidth - $(".container").width();
    const ovr_mar_left = get_custom_margin / 2;
    $(".containerleft").css("margin-left", ovr_mar_left);
    $(".qrWrapper").css("right", ovr_mar_left);

    $(".goto-home").on("click", () => {
      $("html,body").animate({ scrollTop: 0 }, 1000);
    });

    $(".scroll-next").click(function () {
      const cls = $(this).closest("section").next().offset().top - 50;
      $("html, body").animate({ scrollTop: cls }, 1000);
    });

    if (windowWidth > 992) {
      $(".enq_click, .frmclose").click(() => {
        if ($(".form-container").is(':visible')) {
          $(".form-container").slideUp();
        } else {
          $(".form-container").slideToggle();
        }
        $(".form-container, .frmclose").toggleClass("show");
      });
    }

    $(".menu-icon-mobile").on("click", () => {
      $(".nav-links").slideToggle();
      $(".menu-icon-mobile").toggleClass("active");
    });

    if (windowWidth <= 992) {
      $(".nav-links a").on("click", () => {
        $(".menu-icon-mobile").trigger("click");
      });

      $(".mob_enq_click, .frmclose").on("click", () => {
        $(".form-container").toggleClass("show");
      });
    }

    const aArray = $(".nav-links a").map(function () {
      if (!$(this).hasClass("extLink")) {
        if ($(this).attr("rel")) {
          return $(this).attr("rel");
        }
      }
      return null;
    }).get();

    const handleScroll = () => {
      const windowPos = $(window).scrollTop();
      const windowHeight = $(window).height();
      const docHeight = $(document).height();

      for (let i = 0; i < aArray.length; i++) {
        const theID = aArray[i];
        const divPos = $("#" + theID).offset().top;
        const divHeight = $("#" + theID).outerHeight();
        if (windowPos >= (divPos - gap) && windowPos < ((divPos - gap) + divHeight)) {
          if (!$("a[rel='" + theID + "']").hasClass("active")) {
            $("a[rel='" + theID + "']").addClass("active");
          }
        } else {
          $("a[rel='" + theID + "']").removeClass("active");
        }
      }

      if (windowPos + windowHeight === docHeight) {
        if (!$(".nav-links a:not(.extLink):last-child").hasClass("active")) {
          const navActiveCurrent = $(".active").attr("rel");
          $("a[rel='" + navActiveCurrent + "']").removeClass("active");
          $(".nav-links a:not(.extLink):last-child").addClass("active");
        }
      }
    };

    $(window).on("scroll", handleScroll);

    const revealOnScroll = () => {
      const scrolled = $(window).scrollTop();
      const win_height_padded = $(window).height() * 1.02;

      $(".revealOnScroll:not(.animated)").each(function () {
        const offsetTop = $(this).offset().top;
        if (scrolled + win_height_padded > offsetTop) {
          if ($(this).data("timeout")) {
            window.setTimeout(() => {
              if ($(this).hasClass("path")) {
                $(this).addClass($(this).data("animation"));
              } else {
                $(this).addClass("animated " + $(this).data("animation"));
              }
              $(this).animate("", "slow");
            }, parseInt($(this).data("timeout"), 10));
          } else {
            $(this).addClass("animated " + $(this).data("animation"));
          }
        }
      });
    };

    $(window).on("scroll", revealOnScroll);

    $(".lazy").each(function () {
      if ($(this).attr("data-src")) {
        (this.tagName === "IMG" || this.tagName === "IFRAME") ? $(this).attr("src", $(this).data("src")) : $(this).css("background-image", "url(" + $(this).data("src") + ")");
        $(this).removeAttr("data-src");
      }
    });

    const handleScrollNav = () => {
      const windscroll = $(window).scrollTop();
      if (windscroll >= 50) {
        $("header").addClass("active");
      } else {
        $("header").removeClass("active");
      }
    };

    $(window).on("scroll", handleScrollNav);

    return () => {
      window.removeEventListener("resize", updateDimensions);
      $(window).off("scroll", handleScroll);
      $(window).off("scroll", revealOnScroll);
      $(window).off("scroll", handleScrollNav);
    };
  }, [windowHeight, windowWidth, gap]);

  const toggleFormVisibility = () => {
    setFormVisible(!formVisible);
  };

  const handleLinkCopy = (e) => {
    e.preventDefault();
    const copyText = e.target.getAttribute('data-url');
    navigator.clipboard.writeText(copyText).then(() => {
      setLinkCopied(true);
      setTimeout(() => {
        setLinkCopied(false);
      }, 5000);
    });
  };

  return (
    <>
      <header className="active outfit-font">
        <div className="container pos-rel">
          <div className="row">
            <div id="comp-logo">
              <div className="pull-left col-3 col-sm-5 col-xs-9 brand-logo">
                <a href="javascript:;" className="goto-home">
                  <div className="flex align-center">
                    <div className="flex align-center justify-center gap-x-10">
                      <div>
                        <img className="hidden-sm" src={logo} alt="Logo" />
                        <img className="visible-sm" src={logoMob} alt="Logo" />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-9 col-sm-7 col-xs-3 pull-right">
                <div className="pull-right menu-icon-mobile" style={{ "display": "none" }}>
                  <span className="bar1"></span>
                  <span className="bar2"></span>
                  <span className="bar3"></span>
                </div>
                <div className="col-12 lower">
                  <div className="nav-links">
                    <button onClick={toggleFormVisibility} className="enquire-btn hovtype1 open-form" id="hid-on-mob">GET FREE ACCESS</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </header>

      {formVisible && (
        <div className="overlay-custom poptheform" id="overlay">
          <div className="popup-custom">
            <button className="close-custom" onClick={toggleFormVisibility} data-event-category="Section" data-event-action="Click" data-event-name="Close">
              <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.5155 10.4678C20.5155 5.22891 16.1518 0.978516 10.7733 0.978516C5.39473 0.978516 1.03101 5.22891 1.03101 10.4678C1.03101 15.7066 5.39473 19.957 10.7733 19.957C16.1518 19.957 20.5155 15.7066 20.5155 10.4678Z" stroke="#C4C4C4" strokeOpacity="0.8" strokeWidth="1.33333" strokeMiterlimit="10" />
                <path d="M7.52637 13.6313L14.0212 7.30518M14.0212 13.6313L7.52637 7.30518L14.0212 13.6313Z" stroke="#C4C4C4" strokeOpacity="0.8" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
            <div className="formdataWrapper text-center">
              <div className="logo">
                <img className="img-responsive" src={khichiqGrouped} alt="Khichiq" />
              </div>
              <div className="content">
                Be among the first ones to experience it! <br /> Scan QR to get the App
              </div>
              <div className="qr-scanner">
                <img className="img-responsive" src={qrCode2} alt="QR" />
              </div>
              <div className="theme-btn-wrapper clicktoshareWraper">
                <button onClick={handleLinkCopy} className="theme-btn clicktoshare" data-url="https://www.khichiq.com/">SHARE WITH FRIENDS</button>
              </div>
              {linkCopied && (
                <div className="closingtxtWrapper" style={{ display: 'flex' }}>
                  <div>
                    <img className="img-responsive" src={done} alt="Tick" />
                  </div>
                  <div className="closingtxt">
                    Link copied! Paste it on any platform to share!
                  </div>
                </div>
              )}
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
